<template>
  <a-table
    :columns="columns"
    :rowKey="record => record.Id"
    :dataSource="warehouse"
    :pagination="false"
    :loading="loading"
    @change="handleTableChange"
  >
  </a-table>
</template>
<script>
export default {
  data: () => ({
    warehouses: undefined,
    pagination: {},
    loading: false,
    columns: [
      {
        title: 'Склад',
        dataIndex: 'Name',
        scopedSlots: { customRender: 'Name' },
      },
      {
        title: 'Действие',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch() {
      this.$api.getWarehousesReport(false, (response) => {
        const { data } = response.data;
        this.warehouses = data;
      });
    },
  },
};
</script>
